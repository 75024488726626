import { useEffect, useState } from "react";
import { getNumbersWithRegistered, getSizeControl } from "../api/request";
import InfoBox from "../components/InfoBox";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import DropdownMenu from "../components/DropdownMenu";
import axios from "axios";
import { URL } from "../utils/utils";
import Resizer from "react-image-file-resizer";
import Cookies from "js-cookie";

function AddData() {
    const [info, setInfo] = useState(null);
    const [numbers, setNumbers] = useState(null);
    const navigate = useNavigate();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedNumber, setSelectedNumber] = useState("");
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [formData, setFormData] = useState({
        number: "",
        result: "",
        explanation: "",
        actionTaken: ""
    });

    useEffect(() => {
        const fetchNumbers = async () => {
            try {
                const res = await getNumbersWithRegistered();
                if (res.status) {
                    setNumbers(res.numbers);
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchNumbers();
    }, []);

    const handleNumberIndex = (index) => {
        setFormData({ ...formData, number: numbers[index]._id });
        setSelectedNumber(numbers[index].number);
    }

    const handleResultToggle = (newResult) => {
        setFormData({ ...formData, result: newResult });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // eslint-disable-next-line
    const getOriginalDimensions = (file) => {
        return new Promise((resolve) => {
            const img = new Image();

            img.onload = () => {
                const originalWidth = img.naturalWidth;
                const originalHeight = img.naturalHeight;

                resolve({ originalWidth, originalHeight });
            };

            if (typeof URL !== 'undefined' && typeof URL.createObjectURL === 'function') {
                img.src = URL.createObjectURL(file);
            } else {
                const reader = new FileReader();

                reader.onload = (event) => {
                    img.src = event.target.result;
                };

                reader.readAsDataURL(file);
            }
        });
    };


    const handleFileChange = async (e) => {
        setIsFileLoading(true);
        const files = e.target.files;
        const newSelectedFiles = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // if (file.type.startsWith('image/')) {
            //     const originalDimensions = await getOriginalDimensions(file);
            //     const width = originalDimensions.originalWidth;
            //     const height = originalDimensions.originalHeight;
            //     const resizedFile = await resizeFile(file, width, height);
            //     newSelectedFiles.push(resizedFile);
            // } else {
            const newFile = new File([file], turkishToEnglish(file.name), { type: file.type });
            newSelectedFiles.push(newFile);
            // }

            if (files.length - 1 === i) {
                setIsFileLoading(false);
            }
        }

        setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    };

    const turkishToEnglish = (inputString) => {
        var convertedString = inputString
            .replace(/ğ/g, 'g')
            .replace(/Ğ/g, 'G')
            .replace(/ı/g, 'i')
            .replace(/İ/g, 'I')
            .replace(/ş/g, 's')
            .replace(/Ş/g, 'S')
            .replace(/ç/g, 'c')
            .replace(/Ç/g, 'C')
            .replace(/ü/g, 'u')
            .replace(/Ü/g, 'U')
            .replace(/ö/g, 'o')
            .replace(/Ö/g, 'O');

        return convertedString;
    }

    // eslint-disable-next-line
    const resizeFile = (file, width, height) => new Promise(resolve => {
        var newWidth, newHeight;
        const rate = width / height;

        if (width > height) {
            newWidth = 1000;
            newHeight = newWidth / rate;
        } else {
            newHeight = 700;
            newWidth = newHeight * rate;
        }

        Resizer.imageFileResizer(file, newWidth, newHeight, 'JPEG', 100, 0,
            uri => {
                const blob = dataURItoBlob(uri);

                const resizedFile = new File([blob], turkishToEnglish(file.name), { type: 'image/jpeg' });

                resolve(resizedFile);
            }, 'base64');
    });

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.number === "" || formData.result === "") {
            setInfo("Tüm alanları doldurun.")
            setTimeout(() => {
                setInfo(null);
            }, 2000);
            return;
        }

        setInfo("Kaydediliyor...");

        if (selectedFiles.length > 0) {
            try {
                const res = await getSizeControl(selectedNumber);
                if (!res.status) {
                    setInfo(res.message);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        }

        try {
            const config = {
                headers: {
                    authorization: `Bearer ${Cookies.get('skysan-token')}`
                }
            };

            const form = new FormData();
            selectedFiles.forEach((file, index) => {
                form.append(`files`, file);
            });
            form.append(`number`, formData.number);
            form.append(`result`, formData.result);
            form.append(`explanation`, formData.explanation);
            form.append(`actionTaken`, formData.actionTaken);
            const res = await axios.post(`${URL}api/data/insert-data`, form, config);
            if (res.data.status) {
                setInfo(res.data.message);
            } else {
                setInfo("İşlem başarısız.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <div className="headerBar">
                <p>Kayıt ekle</p>
                <div
                    onClick={() => {
                        navigate("/data");
                    }}
                    className='btn-primary'>
                    <FiChevronLeft className='w-5 h-5 mr-1' />
                    <p>Kayıtlar</p>
                </div>
            </div>
            <div className="w-full 
                            bg-white
                            shadow-lg
                            rounded
                            p-5">

                <form
                    onSubmit={handleSubmit}
                    className='w-full flex flex-col'>
                    <div className='w-full flex flex-col md:flex-row'>
                        <div className='w-full mr-0 md:mr-5'>
                            <div className='md:w-80 flex flex-col mb-6'>
                                <label className='text-slate-600 font-medium mb-1'>PCT</label>
                                {
                                    numbers
                                        ? <DropdownMenu
                                            selection={formData.number}
                                            selectedIndexNo={handleNumberIndex}
                                            options={numbers.map(item => item.number)} />
                                        : ""
                                }

                            </div>
                            <div className='md:w-80 flex flex-col mb-6'>
                                <label className='text-slate-600 font-medium mb-1'>Sonuç</label>
                                <DropdownMenu
                                    onToggle={handleResultToggle}
                                    options={["Uygun", "Uygun Değil", "Kontrolsüz", "Rapor Bekliyor", "Ekipman Yenilendi"]} />
                            </div>
                            <div className='flex flex-col mb-6'>
                                <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Kusur Açıklama</label>
                                <textarea
                                    onChange={handleChange}
                                    name="explanation"
                                    value={formData.explanation}
                                    maxLength="500"
                                    rows="4" cols="50"
                                    className="w-full h-32 border border-gray-300 rounded p-2
                                    text-sm font-medium text-top text-gray-700 outline-none resize-none"></textarea>
                                <p className="text-slate-600 flex justify-end font-medium text-sm">{formData.explanation.length}/500</p>
                            </div>
                            <div className='flex flex-col mb-6'>
                                <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Alınan Aksiyonlar</label>
                                <textarea
                                    onChange={handleChange}
                                    name="actionTaken"
                                    value={formData.actionTaken}
                                    maxLength="500"
                                    rows="4" cols="50"
                                    className="w-full h-32 border border-gray-300 rounded p-2
                                    text-sm font-medium text-top text-gray-700 outline-none resize-none"></textarea>
                                <p className="text-slate-600 flex justify-end font-medium text-sm">{formData.actionTaken.length}/500</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col mb-10">
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Arşiv</label>
                        <div className="flex flex-row items-center">
                            <input
                                type="file"
                                onChange={handleFileChange}
                                multiple
                                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .jfif, .dwg"
                                className="file" />
                            {
                                isFileLoading
                                    ? <label className='text-slate-600 font-medium mb-1 text-sm'>Dosya(lar) yükleniyor...</label>
                                    : ""
                            }
                        </div>
                        <label className='text-slate-600 font-medium mb-1 text-sm'>
                            Desteklenen dosya türleri: .jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .jfif, .dwg
                        </label>
                    </div>

                    <button
                        type="submit"
                        className='btn-secondary'>
                        Kaydet
                    </button>
                </form>
                {
                    info ? <InfoBox content={info} /> : ""
                }
            </div>
        </div>
    )
}

export default AddData;
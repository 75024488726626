import { useEffect, useState } from "react";
import { FiChevronLeft, FiX } from "react-icons/fi";
import InfoBox from "./InfoBox";
import { deleteData, deleteFile, getFilesById, getSizeControl } from "../api/request";
import { URL } from "../utils/utils";
import axios from "axios";
import PDFLogo from "../pdf.png";
import EXCELLogo from "../excel.png";
import WORDLogo from "../word.png";
import VIDEOLogo from "../video.png";
import DOCUMENTLogo from "../document.png";
import DropdownMenu from "./DropdownMenu";
import Resizer from "react-image-file-resizer";
import Cookies from "js-cookie";

function DataDetail({ data, pageIsDataDetail }) {
    const [info, setInfo] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [isImageFocus, setIsImageFocus] = useState(false);
    const [imageFocus, setImageFocus] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [formData, setFormData] = useState({
        explanation: data.explanation,
        actionTaken: data.actionTaken,
        result: data.result,
        nextDate: `${data.nextDate.split(".")[2]}-${data.nextDate.split(".")[1]}-${data.nextDate.split(".")[0]}`
    });

    const fetchFile = async () => {
        try {
            const res = await getFilesById(data._id)
            if (res.status) {
                setFileList(res.files);
            }
        } catch (error) {
            console.error('Hata:', error);
        }
    }

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem("skysan")));
        fetchFile();
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const updateData = async () => {
        setInfo("Güncelleniyor...");

        if (selectedFiles.length > 0) {
            try {
                const res = await getSizeControl(data.number);
                if (!res.status) {
                    setInfo(res.message);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        }

        try {
            const config = {
                headers: {
                    authorization: `Bearer ${Cookies.get('skysan-token')}`
                }
            };

            const form = new FormData();
            selectedFiles.forEach((file, index) => {
                form.append(`files`, file);
            });
            form.append(`dataID`, data._id);
            form.append(`result`, formData.result);
            form.append(`explanation`, formData.explanation);
            form.append(`actionTaken`, formData.actionTaken);
            form.append(`nextDate`, formData.nextDate);
            const res = await axios.post(`${URL}api/data/update-data`, form, config);
            if (res.data.status) {
                setInfo(res.data.message);
                setSelectedFiles([]);
                fetchFile();
            } else {
                setInfo("İşlem başarısız.");
            }
        } catch (error) {
            console.log(error);
        }
    }

    // eslint-disable-next-line
    const getOriginalDimensions = (file) => {
        return new Promise((resolve) => {
            const img = new Image();

            img.onload = () => {
                const originalWidth = img.naturalWidth;
                const originalHeight = img.naturalHeight;

                resolve({ originalWidth, originalHeight });
            };

            if (typeof URL !== 'undefined' && typeof URL.createObjectURL === 'function') {
                img.src = URL.createObjectURL(file);
            } else {
                const reader = new FileReader();

                reader.onload = (event) => {
                    img.src = event.target.result;
                };

                reader.readAsDataURL(file);
            }
        });
    };


    const handleFileChange = async (e) => {
        setIsFileLoading(true);
        const files = e.target.files;
        const newSelectedFiles = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // if (file.type.startsWith('image/')) {
            //     const originalDimensions = await getOriginalDimensions(file);
            //     const width = originalDimensions.originalWidth;
            //     const height = originalDimensions.originalHeight;
            //     const resizedFile = await resizeFile(file, width, height);
            //     newSelectedFiles.push(resizedFile);
            // } else {
            const newFile = new File([file], turkishToEnglish(file.name), { type: file.type });
            newSelectedFiles.push(newFile);
            // }

            if (files.length - 1 === i) {
                setIsFileLoading(false);
            }
        }

        setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    };

    const turkishToEnglish = (inputString) => {
        var convertedString = inputString
            .replace(/ğ/g, 'g')
            .replace(/Ğ/g, 'G')
            .replace(/ı/g, 'i')
            .replace(/İ/g, 'I')
            .replace(/ş/g, 's')
            .replace(/Ş/g, 'S')
            .replace(/ç/g, 'c')
            .replace(/Ç/g, 'C')
            .replace(/ü/g, 'u')
            .replace(/Ü/g, 'U')
            .replace(/ö/g, 'o')
            .replace(/Ö/g, 'O');

        return convertedString;
    }

    // eslint-disable-next-line
    const resizeFile = (file, width, height) => new Promise(resolve => {
        var newWidth, newHeight;
        const rate = width / height;

        if (width > height) {
            newWidth = 1000;
            newHeight = newWidth / rate;
        } else {
            newHeight = 700;
            newWidth = newHeight * rate;
        }

        Resizer.imageFileResizer(file, newWidth, newWidth, 'JPEG', 100, 0,
            uri => {
                const blob = dataURItoBlob(uri);

                const resizedFile = new File([blob], turkishToEnglish(file.name), { type: 'image/jpeg' });

                resolve(resizedFile);
            }, 'base64');
    });

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const handleDeleteFile = async (fileID) => {
        const isConfirmed = window.confirm("Belge Silmek İstediğinize Emin misiniz?");
        if (isConfirmed) {
            try {
                const res = await deleteFile(fileID);
                if (res.status) {
                    setInfo(res.message);
                    setFileList((prev) => prev.filter(item => item._id !== fileID));
                } else {
                    setInfo("Belge silinemedi.")
                }
                setTimeout(() => {
                    setInfo(null);
                }, 2000);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleResultToggle = (newResult) => {
        setFormData({ ...formData, result: newResult });
    };

    const handleDeleteData = async () => {
        const isConfirmed = window.confirm("Kayıt ve İçerisindeki Belgeler Silinecektir. Emin misiniz?");
        if (isConfirmed) {
            try {
                setInfo("Siliniyor...");
                const res = await deleteData(data._id);
                if (res.status) {
                    pageIsDataDetail(false);
                } else {
                    setInfo("Belge silinemedi.")
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            // ESC
            if (event.keyCode === 27) {
                setIsImageFocus(false);
                setImageFocus(null);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div>
            {
                isImageFocus
                    ? <div className="w-full h-full bg-[#00000066] z-50 absolute top-0 left-0 flex items-center justify-center flex-row">
                        <img
                            src={`${URL}uploads/${imageFocus}`}
                            className="w-auto h-5/6"
                            alt={`${imageFocus}`}
                        />
                        <FiX
                            className="w-7 h-7 absolute top-4 right-4 cursor-pointer"
                            onClick={() => {
                                setIsImageFocus(false);
                                setImageFocus(null);
                            }} />

                    </div>
                    : ""
            }
            <div className="headerBar">
                <p>Kayıt detayı</p>
                <div
                    onClick={() => {
                        pageIsDataDetail(false);
                    }}
                    className='btn-primary'>
                    <FiChevronLeft className='w-5 h-5 mr-1' />
                    <p>Kayıtlar</p>
                </div>
            </div>

            <div className="w-full bg-white shadow-lg rounded p-5">

                <table>
                    <tbody className="text-left text-sm">
                        <tr>
                            <th className="text-slate-700 font-bold">PCT</th>
                            <td className="px-2">{data.number}</td>
                        </tr>
                        <tr>
                            <th className="text-slate-700 font-bold">Ekipman adı</th>
                            <td className="px-2">{data.equipmentName}</td>
                        </tr>
                        <tr>
                            <th className="text-slate-700 font-bold">Marka</th>
                            <td className="px-2">{data.brand}</td>
                        </tr>
                        <tr>
                            <th className="text-slate-700 font-bold">Tarih</th>
                            <td className="px-2">{data.date}</td>
                        </tr>
                    </tbody>
                </table>

                <hr className="my-3" />

                <div className='md:w-80 flex flex-col mb-6'>
                    <label className="text-slate-700 font-bold text-sm">Sonuç</label>
                    <DropdownMenu
                        selection={formData.result}
                        onToggle={handleResultToggle}
                        options={["Uygun", "Uygun Değil", "Kontrolsüz", "Rapor Bekliyor", "Ekipman Yenilendi"]} />
                </div>

                <div className='md:w-80 flex flex-col mb-6'>
                    <label className="text-slate-700 font-bold text-sm">Sonraki Tarih</label>
                    <input
                        name="nextDate"
                        className="w-full border border-gray-300 rounded p-2 text-sm font-medium 
                                    text-top text-gray-700 outline-none resize-none"
                        type="date"
                        onChange={handleChange}
                        value={formData.nextDate} />
                </div>

                <hr className="my-3" />

                <div>
                    <p className="text-slate-700 font-bold text-sm mr-2 mt-2">Arşiv</p>
                    <div className="flex flex-row flex-wrap">

                        {fileList.map((file, index) => {
                            return (() => {
                                switch (file.fileType) {
                                    case "image":
                                        return (
                                            <div key={index} className="w-24 h-28 m-1.5 flex flex-col group">
                                                <div className="w-24 h-32">
                                                    <img
                                                        key={index}
                                                        src={`${URL}uploads/${file.path}`}
                                                        alt={`${index}`}
                                                        className="w-24 h-24 cursor-pointer"
                                                        onClick={() => {
                                                            setIsImageFocus(true);
                                                            setImageFocus(file.path);
                                                        }}
                                                    />
                                                    <div className="w-7 h-7 -mt-24 flex items-center justify-center">
                                                        <FiX
                                                            className="w-5 h-5 invisible group-hover:visible cursor-pointer"
                                                            onClick={() => handleDeleteFile(file._id)} />
                                                    </div>

                                                </div>

                                                <p className="text-sm truncate flex items-center">{file.filename}</p>
                                            </div>
                                        );
                                    case "pdf":
                                        return (
                                            <div key={index} className="w-24 h-28 m-1.5 flex flex-col group">
                                                <div className="w-24 h-28">
                                                    <a href={`${URL}uploads/${file.path}`} target="blank">
                                                        <img
                                                            key={index}
                                                            src={PDFLogo}
                                                            alt={`${index}`}
                                                            className="w-24 h-24 cursor-pointer"
                                                        />
                                                    </a>
                                                    <div className="w-7 h-7 -mt-24 flex items-center justify-center">
                                                        <FiX
                                                            className="w-5 h-5 invisible group-hover:visible cursor-pointer"
                                                            onClick={() => handleDeleteFile(file._id)} />
                                                    </div>
                                                </div>

                                                <p className="text-sm truncate flex items-center">{file.filename}</p>
                                            </div>
                                        );
                                    case "excel":
                                        return (
                                            <div key={index} className="w-24 h-28 m-1.5 flex flex-col group">
                                                <div className="w-24 h-28">
                                                    <a href={`${URL}uploads/${file.path}`} target="blank">
                                                        <img
                                                            key={index}
                                                            src={EXCELLogo}
                                                            alt={`${index}`}
                                                            className="w-24 h-24 cursor-pointer"
                                                        />
                                                    </a>
                                                    <div className="w-7 h-7 -mt-24 flex items-center justify-center">
                                                        <FiX
                                                            className="w-5 h-5 invisible group-hover:visible cursor-pointer"
                                                            onClick={() => handleDeleteFile(file._id)} />
                                                    </div>
                                                </div>

                                                <p className="text-sm truncate flex items-center">{file.filename}</p>
                                            </div>
                                        );
                                    case "word":
                                        return (
                                            <div key={index} className="w-24 h-28 m-1.5 flex flex-col group">
                                                <div className="w-24 h-28">
                                                    <a href={`${URL}uploads/${file.path}`} target="blank">
                                                        <img
                                                            key={index}
                                                            src={WORDLogo}
                                                            alt={`${index}`}
                                                            className="w-24 h-24 cursor-pointer"
                                                        />
                                                    </a>
                                                    <div className="w-7 h-7 -mt-24 flex items-center justify-center">
                                                        <FiX
                                                            className="w-5 h-5 invisible group-hover:visible cursor-pointer"
                                                            onClick={() => handleDeleteFile(file._id)} />
                                                    </div>
                                                </div>

                                                <p className="text-sm truncate flex items-center">{file.filename}</p>
                                            </div>
                                        );
                                    case "video":
                                        return (
                                            <div key={index} className="w-24 h-28 m-1.5 flex flex-col group">
                                                <div className="w-24 h-28">
                                                    <a href={`${URL}uploads/${file.path}`} target="blank">
                                                        <img
                                                            key={index}
                                                            src={VIDEOLogo}
                                                            alt={`${index}`}
                                                            className="w-24 h-24 cursor-pointer"
                                                        />
                                                    </a>
                                                    <div className="w-7 h-7 -mt-24 flex items-center justify-center">
                                                        <FiX
                                                            className="w-5 h-5 invisible group-hover:visible cursor-pointer"
                                                            onClick={() => handleDeleteFile(file._id)} />
                                                    </div>
                                                </div>

                                                <p className="text-sm truncate flex items-center">{file.filename}</p>
                                            </div>
                                        );
                                    case "document":
                                        return (
                                            <div key={index} className="w-24 h-28 m-1.5 flex flex-col group">
                                                <div className="w-24 h-28">
                                                    <a href={`${URL}uploads/${file.path}`} target="blank">
                                                        <img
                                                            key={index}
                                                            src={DOCUMENTLogo}
                                                            alt={`${index}`}
                                                            className="w-24 h-24 cursor-pointer"
                                                        />
                                                    </a>
                                                    <div className="w-7 h-7 -mt-24 flex items-center justify-center">
                                                        <FiX
                                                            className="w-5 h-5 invisible group-hover:visible cursor-pointer"
                                                            onClick={() => handleDeleteFile(file._id)} />
                                                    </div>
                                                </div>

                                                <p className="text-sm truncate flex items-center">{file.filename}</p>
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })()
                        })}
                    </div>
                </div>

                <div className="flex flex-col my-4">
                    <div className="flex flex-row items-center">
                        <input
                            type="file"
                            onChange={handleFileChange}
                            multiple
                            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .jfif, .dwg"
                            className="file" />
                        {
                            isFileLoading
                                ? <label className='text-slate-600 font-medium mb-1 text-sm'>Dosya(lar) yükleniyor...</label>
                                : ""
                        }
                    </div>
                    <label className='text-slate-600 font-medium mb-1 text-sm'>
                        Desteklenen dosya türleri: .jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .jfif, .dwg
                    </label>
                </div>


                <hr className="my-3" />

                <div className="flex flex-col mb-5">
                    <p className="text-slate-700 font-bold text-sm my-1">Kusur Açıklama</p>
                    <textarea
                        onChange={handleChange}
                        name="explanation"
                        value={formData.explanation}
                        maxLength="500"
                        rows="4" cols="50"
                        className="w-full h-32 border border-gray-300 rounded p-2 text-sm font-medium 
                                    text-top text-gray-700 outline-none resize-none">
                    </textarea>
                    <p className="text-slate-600 flex justify-end font-medium text-sm">{formData.explanation.length}/500</p>
                </div>

                <div className="flex flex-col mb-5">
                    <p className="text-slate-700 font-bold text-sm my-1">Alınan Aksiyonlar</p>
                    <textarea
                        onChange={handleChange}
                        name="actionTaken"
                        value={formData.actionTaken}
                        maxLength="500"
                        rows="4" cols="50"
                        className="w-full h-32 border border-gray-300 rounded p-2 text-sm font-medium
                                    text-top text-gray-700 outline-none resize-none">
                    </textarea>
                    <p className="text-slate-600 flex justify-end font-medium text-sm">{formData.actionTaken.length}/500</p>
                </div>

                <div className="flex justify-between">
                    <button
                        className="btn-secondary"
                        onClick={updateData}>Kaydet</button>
                    {
                        currentUser?.role === "Admin" &&
                        <button
                            className="btn-delete"
                            onClick={handleDeleteData}>Sil</button>
                    }

                </div>
                {
                    info ? <InfoBox content={info} /> : ""
                }

            </div>
        </div >
    )
}

export default DataDetail;
import { useEffect, useState } from "react";
import { getLocations, getNumbersWithoutRegistered } from "../api/request";
import DropdownMenu from "./DropdownMenu";
import InfoBox from "./InfoBox";
import Resizer from "react-image-file-resizer";
import InputDropdownMenu from "./InputDropdownMenu";

function FormEquipment(props) {
    const [formData, setFormData] = useState(props.equipment);
    const [numbers, setNumbers] = useState(null);
    const [locations, setLocations] = useState(null);
    const [info, setInfo] = useState(null);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
        const fetchNumbers = async () => {
            try {
                const res = await getNumbersWithoutRegistered();
                if (res.status) {
                    setNumbers(res.numbers);
                }
            } catch (error) {
                console.log(error);
            }
        }

        const fetchLocations = async () => {
            const user = JSON.parse(localStorage.getItem("skysan"));
            try {
                const res = await getLocations(user._id);
                if (res.status) {
                    setLocations(res.locations.filter(item => item.isActive));
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchNumbers();
        fetchLocations();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.number === "" || formData.location === "") {
            setInfo("PCT ve Bölüm alanları dolu olmalı.")
            setTimeout(() => {
                setInfo(null);
            }, 2000);
            return;
        }

        const form = new FormData();
        selectedFiles.forEach((file, index) => {
            form.append(`files`, file);
        });
        form.append(`_id`, formData._id || null);
        form.append(`number`, formData.number._id || formData.number);
        form.append(`location`, formData.location._id || formData.location);
        form.append(`equipmentCategory`, formData.equipmentCategory);
        form.append(`equipmentName`, formData.equipmentName);
        form.append(`brand`, formData.brand);
        form.append(`manufactureYear`, formData.manufactureYear);
        form.append(`serialNo`, formData.serialNo);
        form.append(`capacity`, formData.capacity);
        form.append(`capacityType`, formData.capacityType);
        form.append(`workingPressure`, formData.workingPressure);
        form.append(`piece`, formData.piece);
        form.append(`SAPEquipmentNo`, formData.SAPEquipmentNo);
        form.append(`assetLabelNo`, formData.assetLabelNo);
        form.append(`producionStopIsItNecessary`, formData.producionStopIsItNecessary);
        form.append(`controlPeriod`, formData.controlPeriod || "");
        form.append(`equipmentExplanation`, formData.equipmentExplanation);
        form.append(`isActive`, formData.isActive);
        props.onToggle(form, formData.number);
    };

    const handleNumberIndex = (index) => {
        setFormData({ ...formData, number: numbers[index]._id });
    }

    const handleLocationIndex = (index) => {
        setFormData({ ...formData, location: locations[index]._id });
    }

    const handleCapacityTypeToggle = (newCapacityType) => {
        setFormData({ ...formData, capacityType: newCapacityType });
    };

    // eslint-disable-next-line
    const getOriginalDimensions = (file) => {
        return new Promise((resolve) => {
            const img = new Image();

            img.onload = () => {
                const originalWidth = img.naturalWidth;
                const originalHeight = img.naturalHeight;

                resolve({ originalWidth, originalHeight });
            };

            if (typeof URL !== 'undefined' && typeof URL.createObjectURL === 'function') {
                img.src = URL.createObjectURL(file);
            } else {
                const reader = new FileReader();

                reader.onload = (event) => {
                    img.src = event.target.result;
                };

                reader.readAsDataURL(file);
            }
        });
    };

    const handleFileChange = async (e) => {
        setIsFileLoading(true);
        const files = e.target.files;
        const newSelectedFiles = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // if (file.type.startsWith('image/')) {
            //     const originalDimensions = await getOriginalDimensions(file);
            //     const width = originalDimensions.originalWidth;
            //     const height = originalDimensions.originalHeight;
            //     const resizedFile = await resizeFile(file, width, height);
            //     newSelectedFiles.push(resizedFile);
            // } else {
            const newFile = new File([file], turkishToEnglish(file.name), { type: file.type });
            newSelectedFiles.push(newFile);
            // }

            if (files.length - 1 === i) {
                setIsFileLoading(false);
            }
        }

        setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    };

    const turkishToEnglish = (inputString) => {
        var convertedString = inputString
            .replace(/ğ/g, 'g')
            .replace(/Ğ/g, 'G')
            .replace(/ı/g, 'i')
            .replace(/İ/g, 'I')
            .replace(/ş/g, 's')
            .replace(/Ş/g, 'S')
            .replace(/ç/g, 'c')
            .replace(/Ç/g, 'C')
            .replace(/ü/g, 'u')
            .replace(/Ü/g, 'U')
            .replace(/ö/g, 'o')
            .replace(/Ö/g, 'O');

        return convertedString;
    }

    // eslint-disable-next-line
    const resizeFile = (file, width, height) => new Promise(resolve => {
        var newWidth, newHeight;
        const rate = width / height;

        if (width > height) {
            newWidth = 1000;
            newHeight = newWidth / rate;
        } else {
            newHeight = 700;
            newWidth = newHeight * rate;
        }

        Resizer.imageFileResizer(file, newWidth, newWidth, 'JPEG', 100, 0,
            uri => {
                const blob = dataURItoBlob(uri);

                const resizedFile = new File([blob], turkishToEnglish(file.name), { type: 'image/jpeg' });

                resolve(resizedFile);
            }, 'base64');
    });

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const handleProducionStopIsItNecessaryToggle = (newProducionStopIsItNecessary) => {
        setFormData({ ...formData, producionStopIsItNecessary: newProducionStopIsItNecessary });
    }

    const handleIsActiveToggle = (newIsActive) => {
        setFormData({ ...formData, isActive: newIsActive === "EVET" });
    }

    const handleControlPeriodToggle = (newControlPeriod) => {
        setFormData({ ...formData, controlPeriod: newControlPeriod });
    }

    const handleEquipmentCategoryToggle = (newEquipmentCategory) => {
        setFormData({ ...formData, equipmentCategory: newEquipmentCategory });
    }

    return (
        <form
            onSubmit={handleSubmit}
            className='w-full flex flex-col'>
            <div className='w-full flex flex-col md:flex-row'>
                <div className='w-full md:w-80 mr-0 md:mr-5'>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>PCT</label>
                        {
                            numbers
                                ? <DropdownMenu
                                    selection={formData.number.number}
                                    selectedIndexNo={handleNumberIndex}
                                    options={numbers.map(item => item.number)}
                                    isVisible={props.isVisible} />
                                : ""
                        }

                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Bölüm</label>
                        {
                            locations
                                ? <DropdownMenu
                                    selection={formData.location.location}
                                    selectedIndexNo={handleLocationIndex}
                                    options={locations.map(item => item.location)} />
                                : ""
                        }
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Ekipman Kategori</label>
                        <DropdownMenu
                            selection={formData.equipmentCategory}
                            onToggle={handleEquipmentCategoryToggle}
                            options={
                                [
                                    "BASINÇLI KAP",
                                    "ENDÜSTRİYEL KAPI",
                                    "ENDÜSTRİYEL RAF",
                                    "İSKELE",
                                    "İŞ MAKİNESİ",
                                    "KALDIRMA EKİPMANI",
                                    "KONVEYÖR",
                                    "MEKİK",
                                    "RKU",
                                    "SAPAN",
                                    "TEHLİKELİ KİMYASAL TANKI",
                                    "TESİSAT",
                                    "TEZGAH",
                                ]
                            } />
                    </div>

                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Ekipman Adı</label>
                        <input
                            type="text"
                            name="equipmentName"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.equipmentName} />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Marka (Model)</label>
                        <input
                            type="text"
                            name="brand"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.brand} />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>İmalat Yılı</label>
                        <input
                            type="text"
                            name="manufactureYear"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.manufactureYear} />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Bunge Varlık Etiket No</label>
                        <input
                            type="text"
                            name="assetLabelNo"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.assetLabelNo} />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Aktif Mi?</label>
                        <DropdownMenu
                            selection={formData.isActive ? "EVET" : "HAYIR"}
                            onToggle={handleIsActiveToggle}
                            options={["EVET", "HAYIR"]} />
                    </div>

                </div>
                <div className='w-full md:w-80 mr-0 md:mr-5'>

                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Kontrol için Üretim Duruşu Gerekli Mi?</label>
                        <DropdownMenu
                            selection={formData.producionStopIsItNecessary}
                            onToggle={handleProducionStopIsItNecessaryToggle}
                            options={["EVET", "HAYIR"]} />
                    </div>

                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Seri No</label>
                        <input
                            type="text"
                            name="serialNo"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.serialNo} />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>SAP Ekipman No</label>
                        <input
                            type="text"
                            name="SAPEquipmentNo"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.SAPEquipmentNo} />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Kapasite</label>
                        <div className="flex flex-row">
                            <input
                                type="text"
                                name="capacity"
                                className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                                onChange={handleChange}
                                value={formData.capacity} />
                            <div className="w-48 flex items-center">
                                <DropdownMenu
                                    selection={formData.capacityType}
                                    onToggle={handleCapacityTypeToggle}
                                    options={["Ton/h", "Lt", "Kg", "Ton", "kW"]} />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Çalışma Basıncı (bar)</label>
                        <input
                            type="text"
                            name="workingPressure"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.workingPressure} />
                    </div>
                    {/* <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Adet</label>
                        <input
                            type="text"
                            name="piece"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.piece} />
                    </div> */}
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Kontrol Periyodu (gün)</label>
                        <InputDropdownMenu
                            selection={formData.controlPeriod}
                            onToggle={handleControlPeriodToggle}
                            options={[90, 365, 1095]} />
                    </div>
                    <div className="flex flex-col mb-5">
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Açıklama</label>
                        <textarea
                            onChange={handleChange}
                            name="equipmentExplanation"
                            value={formData.equipmentExplanation}
                            rows="4" cols="50"
                            className="w-full h-32 border border-gray-300 rounded p-2 text-sm font-medium 
                                    text-top text-gray-700 outline-none resize-none">
                        </textarea>
                    </div>

                </div>
            </div>
            <div className="flex flex-col mb-5">
                <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>Ekipman görüntüleri</label>
                <div className="flex flex-row items-center">
                    <input
                        type="file"
                        onChange={handleFileChange}
                        multiple
                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .jfif, .dwg"
                        className="file" />
                    {
                        isFileLoading
                            ? <label className='text-slate-600 font-medium mb-1 text-sm'>Dosya(lar) yükleniyor...</label>
                            : ""
                    }
                </div>
                <label className='text-slate-600 font-medium mb-1 text-sm'>
                    Desteklenen dosya türleri: .jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .jfif, .dwg
                </label>
            </div>
            <button
                type="submit"
                className='btn-secondary'>
                {props.buttonTitle}
            </button>
            {
                info ? <InfoBox content={info} /> : ""
            }
        </form>
    )
}

export default FormEquipment;